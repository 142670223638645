import { useLocation } from "react-router-dom";
import WebFilterLink from "../../components/common/WebFilterLink";
import { WebFilterTabs } from "../../components/common/WebFilterTab";

const NewsAndEventsFilter = () => {
  const { search } = useLocation();

  const query = new URLSearchParams(search);

  const param = query.get("type");

  return (
    <WebFilterTabs>
      <WebFilterLink to='/news-and-events' className={!param ? "active" : ""}>
        All
      </WebFilterLink>
      <WebFilterLink to='/news-and-events?type=News' className={param === "News" ? "active" : ""}>
        News
      </WebFilterLink>
      <WebFilterLink to='/news-and-events?type=Event' className={param === "Event" ? "active" : ""}>
        Events
      </WebFilterLink>
    </WebFilterTabs>
  );
};

export default NewsAndEventsFilter;