import SectionTitle from "../../../../components/common/SectionTitle";
import SectionContainer from "../../../../components/layout/SectionContainer";
import useCurrentModel from "../../utils/useCurrentModel";
import Specifications from "./Specifications";

function ModelSpecificationsSection() {
  const model = useCurrentModel();

  if (!model) return null;

  return (
    <SectionContainer className='model-section' id='model-specs'>
      <SectionTitle title='Specifications' />
      <Specifications />
    </SectionContainer>
  );
}

export default ModelSpecificationsSection;