import { useState } from "react";
import { ModelVariant } from "../../../../store/models/types";

import { Box, Typography, Stack, Tabs } from "@mui/material";
import {
  VariantTab,
  VariantImgContainer,
  VariantImg,
  variantContentStyle,
  variantBoxStyle,
} from "./PerformanceStyle";
import { appColors } from "../../../../theme";

interface VariantProps {
  variantData: ModelVariant[];
}

const Variant = ({ variantData }: VariantProps) => {
  const [currentTab, setCurrentTab] = useState<number>(0);

  return (
    <Box marginTop={5}>
      <Box borderBottom={`1px solid ${appColors.lightestGray}`}>
        <Tabs
          value={currentTab}
          onChange={(e, v) => setCurrentTab(v)}
          aria-label='performance variant tab'
          variant='scrollable'
          visibleScrollbar={false}
        >
          {variantData.map((v) => (
            <VariantTab key={v.name} label={v.name} />
          ))}
        </Tabs>
      </Box>
      <Stack sx={variantContentStyle}>
        {variantData[currentTab].performance.map((p, i) => (
          <Box key={p.mainLabel + i} sx={variantBoxStyle}>
            <Typography textAlign='center' fontSize={17} fontWeight={600}>
              {p.mainLabel}
            </Typography>
            <VariantImgContainer>
              <VariantImg src={p.imageUrl} alt='performance-variant-img' />
            </VariantImgContainer>
            <Typography textAlign='center' fontSize={23} fontWeight={600}>
              {p.subLabel}
            </Typography>
            <Typography textAlign='center' fontSize={14}>
              {p.unitLabel}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default Variant;