import {
  SET_TESTIMONIAL_ERROR,
  SET_TESTIMONIAL_STATUS,
  LOAD_TESTIMONIAL_DATA,
  TestimonialAction,
  TestimonialListState
} from "./types";

const initialState : TestimonialListState = {
  status: "idle",
  error: undefined,
  data: [],
};

const testimonialReducer = (state = initialState, action: TestimonialAction): TestimonialListState => {
  switch (action.type) {
    case SET_TESTIMONIAL_STATUS:
      return { ...state, status: action.payload.status };
    case SET_TESTIMONIAL_ERROR:
      return { ...state, error: action.payload.error, status: "error" };
    case LOAD_TESTIMONIAL_DATA:
        return { ...state, data: action.payload.data, status: "idle" };
    default:
      return state;
  }
};

export default testimonialReducer;