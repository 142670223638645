import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import WebPrimaryButton from "../../../components/common/WebPrimaryButton";

import TestimonialForm from "./TestimonialForm";

import testimonyBannerImg from "../../../assets/images/testimonial-banner.png";

const TestimonialFormBanner = () => {
  const [formOpen, setFormOpen] = useState(false);
  return (
    <Box
      sx={{
        maxWidth: (theme) => theme.breakpoints.values.lg - 48,
        mx: "auto",
        position: "absolute",
        left: 0,
        right: 0,
        top: "100%",
        transform: "translateY(-50%)",
        bgcolor: "#222",
        backgroundImage: `url(${testimonyBannerImg})`,
        backgroundPosition: "center center",
        backgroundSize: { xs: "cover", md: "cover" },
        backgroundRepeat: "no-repeat",
      }}
    >
      <Stack
        p={{ xs: 4, md: 12 }}
        direction={{ xs: "column", md: "row" }}
        justifyContent='space-between'
        gap={{ xs: 2, md: 0 }}
      >
        <Typography
          fontSize={{ xs: 24, md: 38 }}
          fontWeight='bold'
          color='white'
          maxWidth={{ xs: "100%", md: "480px" }}
          textAlign={{ xs: "center", md: "left" }}
        >
          Would you like to give us a testimonial?
        </Typography>
        <WebPrimaryButton onClick={() => setFormOpen(true)} sx={{ alignSelf: "center" }}>
          Add Testimonial
        </WebPrimaryButton>
      </Stack>
      {formOpen && <TestimonialForm onClose={() => setFormOpen(false)} />}
    </Box>
  );
};

export default TestimonialFormBanner;