import { Redirect, Route, Switch } from 'react-router-dom';
import PartsAndServicePage from './parts-and-service';
import CareersPage from './career';
import HomePage from './home';
import ModelPage from './model';
import CompareModelsPage from './model/compare-models';
import ElitePage from './elite';
import NewsAndEventsPage from './news-and-events/NewsAndEventsPage';
import VirtualShowroomPage from './virtual-showroom';
import PromoPage from './promos';
import AboutUsPage from './about-us';
import PartsAndAccessoriesPage from './parts-and-accessories';
import SearchPage from './search';
import DataPrivacyAct from './home/data-privacy/DataPrivacyAct';
import useScrollToTop from '../hooks/useScrollToTop';
import BackToTopButton from '../components/common/BackToTopButton';

const ContentPage = () => {
  useScrollToTop();

  return (
    <>
      <main>
        <Switch>
          <Route path='/home'>
            <HomePage />
          </Route>
          <Route path='/models'>
            <ModelPage />
          </Route>
          <Route path='/models/compare-models'>
            <CompareModelsPage />
          </Route>
          <Route path='/parts-and-accessories'>
            <PartsAndAccessoriesPage />
          </Route>
          <Route path='/services'>
            <PartsAndServicePage />
          </Route>
          <Route path='/promos'>
            <PromoPage />
          </Route>
          <Route path='/careers'>
            <CareersPage />
          </Route>
          <Route path='/hcbt-elite'>
            <ElitePage />
          </Route>
          <Route path='/news-and-events'>
            <NewsAndEventsPage />
          </Route>
          <Route path='/virtual-showroom'>
            <VirtualShowroomPage />
          </Route>
          <Route path='/about-us'>
            <AboutUsPage />
          </Route>
          <Route path='/data-privacy'>
            <DataPrivacyAct />
          </Route>
          <Route path='/search'>
            <SearchPage />
          </Route>
          <Route path='/data-privacy'>
            <DataPrivacyAct />
          </Route>
          <Redirect from='/' to='/home' />
        </Switch>
      </main>
      <BackToTopButton />
    </>
  );
};

export default ContentPage;