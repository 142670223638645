import { IconButton } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircleIcon from "@mui/icons-material/Circle";
import { controlButtonsStyles } from "./utilStyles";

/***  NEXT BUTTON ****/
interface NextButtonProps {
  onClick: () => void;
  hasNext: boolean;
  label: string;
}

export const NextButton = ({ onClick, hasNext, label }: NextButtonProps) => {
  return (
    <IconButton
      onClick={onClick}
      disabled={!hasNext}
      aria-label={label}
      sx={{
        ...controlButtonsStyles,
        zIndex: 2,
        right: "0",
      }}
    >
      <ArrowForwardIcon />
    </IconButton>
  );
};

/***  PREV BUTTON ****/
interface PrevButtonProps {
  onClick: () => void;
  hasPrev: boolean;
  label: string;
}

export const PrevButton = ({ onClick, hasPrev, label }: PrevButtonProps) => {
  return (
    <IconButton
      onClick={onClick}
      disabled={!hasPrev}
      aria-label={label}
      sx={{
        ...controlButtonsStyles,
        zIndex: 2,
        left: "0",
      }}
    >
      <ArrowBackIcon />
    </IconButton>
  );
};

/***  INDICATOR BUTTON ****/
interface CarouselIndicatorProps {
  clickHandler: (e: any) => void;
  isSelected: boolean;
  index: number;
  label: string;
}

export const CarouselIndicator = ({
  clickHandler,
  isSelected,
  index,
  label,
}: CarouselIndicatorProps) => {
  return (
    <IconButton aria-label={label} onClick={clickHandler} size='small'>
      <CircleIcon
        sx={{
          fontSize: 16,
          color: isSelected ? (theme) => theme.palette.primary.main : "#ccc",
        }}
      />
    </IconButton>
  );
};