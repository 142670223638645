import { IModels } from "../../../store/models/types";

import { Box, Stack, Typography } from "@mui/material";
import { appColors } from "../../../theme/index";
import { modelContainer, imageContainer } from "./ModelStyle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface ModelItemProps {
  index: number;
  models: IModels;
}

const ModelItem = ({ index, models }: ModelItemProps) => {
  return (
    <Stack sx={modelContainer} spacing={2}>
      <Typography fontWeight={600} fontSize={18} color={appColors.dark}>
        {models.price}
      </Typography>
      <Box sx={imageContainer}>
        <img
          src={models.imagePreviewUrl}
          alt={models.name}
          style={{ padding: "0px 10px" }}
        />
      </Box>
      <Typography className="view-model">
        View Model
        <ArrowForwardIcon
          sx={{
            fontSize: 15,
            fontWeight: "800",
            position: "relative",
            top: "2px",
          }}
        />
      </Typography>
    </Stack>
  );
};

export default ModelItem;