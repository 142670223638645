import {
  GridProps,
  Grid,
  Button,
  AccordionDetails,
  AccordionSummary,
  Stack,
  ButtonProps,
  Avatar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { appColors } from "../../../../theme";
import { HondaButtonStyle } from "../../../home/fragments/DisinfectServiceComponents";

type ChildProps = {
  children?: JSX.Element;
};

export const Selection = (el: ChildProps & GridProps) => {
  return (
    <AnimatedGrid
      item
      borderRight={{ xs: "none", sm: `1px solid ${appColors.lightGray}` }}
      xs={12}
      md={3}
      mt={5}
      {...el}
    >
      {el.children}
    </AnimatedGrid>
  );
};

export const AnimatedGrid = styled(Grid, {
  shouldForwardProp: (props) => props !== "hide",
})<{ adjust?: boolean }>(({ theme, adjust }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  height: 70,
  transition: "all ease-in-out 500ms",
  "&.grow": {
    height: 200,
    [theme.breakpoints.down("md")]: {
      height: 250,
    },
  },
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    "&:first-of-type": {
      display: "none",
    },
  },
}));

export const CompareButton = styled(Button, {
  shouldForwardProp: (props) => props !== "hide",
})<{ hide?: boolean }>(({ theme, hide }) => ({
  display: hide ? "none" : "flex",
  fontWeight: "normal",
  textTransform: "capitalize",
  border: "none",
  fontSize: 16,
  "&:hover": {
    border: "none",
  },
}));

export const CustomAvatar = styled(Avatar, {
  shouldForwardProp: (props) => props !== "hide",
})<{ hide?: boolean }>(({ theme, hide }) => ({
  display: hide ? "none" : "flex",
  height: 100,
  width: 150,
  img: {
    objectFit: "contain",
  },
}));

export const CompareAccordion: SxProps = {
  backgroundColor: appColors.lightestGray,
  border: "none",
  boxShadow: "none",
  "&:before": { display: "none" },
};

export const CompareChips = styled("div", {
  shouldForwardProp: (props) => props !== "hide",
})<{ hide?: boolean }>(({ theme, hide }) => ({
  position: "absolute",
  top: 0,
  right: 15,
  display: hide ? "none" : "flex",
  justifyContent: "end",
  alignItems: "center",
  background: appColors.lightestGray,
  overflow: "hidden",
  paddingX: 0,
  width: 25,
  height: 25,
  cursor: "pointer",
  borderRadius: "15px",
  color: theme.palette.primary.main,
  transition: "width ease-in-out 200ms, padding ease-in-out 200ms",
  zIndex: 1,
  "&:hover": {
    width: 100,
    paddingRight: 5,
  },
  [theme.breakpoints.down("md")]: {
    right: 0,
  },
}));

export const CompareAccordionDetails = styled(AccordionDetails)({
  backgroundColor: "white",
  paddingTop: 15,
  paddingBottom: 15,
  borderLeft: `1px solid ${appColors.lightestGray}`,
  borderRight: `1px solid ${appColors.lightestGray}`,
  borderBottom: `1px solid ${appColors.lightestGray}`,
});

export const CompareAccordionSummary = styled(AccordionSummary)(
  ({ theme }) => ({
    paddingBottom: 5,

    "& svg": {
      color: theme.palette.primary.main,
    },
  })
);

interface HondaButtonProps {
  children?: React.ReactNode;
  display?: { xs?: "none" | "flex"; md?: "none" | "flex" };
  hideonprint?: boolean;
  marginy?: number | string;
}

export const HondaButton = (props: HondaButtonProps & ButtonProps) => {
  const { display, children, marginy, hideonprint } = props;
  return (
    <HondaButtonStyle
      {...props}
      variant="contained"
      sx={{
        display: hideonprint ? "none" : display,
        marginTop: marginy,
        marginBottom: marginy,
        paddingX: { md: 10 },
        paddingY: 1.3,
        borderRadius: 0,
        fontWeight: 400,
        boxShadow: "none",
        "&:hover": { boxShadow: "none" },
      }}
    >
      {children}
    </HondaButtonStyle>
  );
};

interface ClampedTextProps {
  text: string;
  width?: number | string;
}
export const ClampedText = ({ text, width = 120 }: ClampedTextProps) => {
  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",

        "& > span": {
          width,
          fontSize: 17,
          fontWeight: 600,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      }}
    >
      <span>{text}</span>
    </Stack>
  );
};

export const CompareItemStyle: SxProps = {
  flexDirection: "row",
  justifyContent: "space-between",
  backgroundColor: "#fafafa",
  borderRadius: 1,
  cursor: "pointer",
  px: { xs: 1, sm: 3 },
};

export const CompareVariantStyle: SxProps = {
  cursor: "pointer",
  flexDirection: { xs: "column", sm: "row" },
  paddingY: 2,
  paddingX: 3,
  backgroundColor: "#fafafa",
  borderRadius: 1,
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  gap: 1,
};

export const CompareVariantDetails = styled(Stack)({
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
});

export const CompareVariantText = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
  color: appColors.mediumGray,
});

export const AccordionContainerStyle: SxProps = {
  overflowX: "auto",
  width: "100%",
  "::-webkit-scrollbar": {
    display: "none",
  },
};

export const selectionContainerStyles: SxProps = {
  flexDirection: "row",
  width: "100%",
  "@media screen and (min-width: 320px) and (max-width: 600px)": {
    flexDirection: "column",
  },
};