import { Avatar, Box, BoxProps, Button, ButtonProps, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import disinfectServiceBackground from "../../../assets/images/disinfect-service-bg.png";
import React from "react";

/** Disinfect Service Container */
export const DisinfectContainer = styled(Grid)({
  background: `url(${disinfectServiceBackground}), linear-gradient(rgba(39,41,40,0.8), rgba(15,15,15,0.95))`,
  backgroundBlendMode: "darken",
  backgroundSize: "cover",
  backgroundPosition: "center",
  minHeight: "100%",
  color: "#fff",
});

/** Responsive Paragraph includes breakpoints to control alignment */
export const ResponsiveTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
}));

export const DisinfectAvatar = styled(Avatar)(({ theme }) => ({
  width: 55,
  height: 55,
  background: "#83BB53",
  [theme.breakpoints.down("md")]: {
    margin: "0 auto",
  },
}));

interface IconBoxProps {
  label: string;
  src: string;
  alt?: string;
  textWidth?: { xs?: number | string; md?: number | string };
}
/** Icon Box for benefits block */
export const IconBox = (props: BoxProps & IconBoxProps) => {
  const { label, src, alt, textWidth } = props;
  return (
    <Box {...props} display={{ xs: "block", md: "flex" }} columnGap={2} alignItems='center'>
      <DisinfectAvatar alt={alt} src={src} />
      <ResponsiveTypography
        fontWeight={600}
        fontSize={15}
        lineHeight={1.2}
        width={textWidth}
        margin={{ xs: "0 auto", md: "0" }}
        paddingTop={{ xs: 1 }}
      >
        {label}
      </ResponsiveTypography>
    </Box>
  );
};

export const HondaButtonStyle = styled(Button)(({ theme }) => ({
  color: "white",
  borderColor: "white",
  borderRadius: 8,
  paddingLeft: 25,
  paddingRight: 25,
  paddingTop: 7,
  paddingBottom: 7,
  textTransform: "capitalize",
  fontSize: 16,
  width: "fit-content",
  // '&:hover': {
  //     borderColor: 'rgb(188, 53, 50)',
  //     backgroundColor: 'rgb(188, 53, 50)'
  // },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

interface HondaButtonProps {
  children?: React.ReactNode;
  display?: { xs?: "none" | "flex"; md?: "none" | "flex" };
  marginy?: number | string;
}

export const HondaButton = (props: HondaButtonProps & ButtonProps) => {
  const { display, children, marginy } = props;
  return (
    <HondaButtonStyle
      {...props}
      variant='outlined'
      endIcon={<ArrowForwardIcon />}
      sx={{
        display: display,
        marginTop: marginy,
        marginBottom: marginy,
      }}
    >
      {children}
    </HondaButtonStyle>
  );
};