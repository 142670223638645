import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ReactNode } from "react";
import "@fontsource/barlow-condensed";
import useThemeLoader from "../hooks/useThemeLoader";

export const appColors = {
  blackHeader: "#111111",
  colorText: "#ccc",
  darkestGray: "#1A2225",
  darkGray: "#222D31",
  mediumGray: "#596164",
  lightGray: "#ccc",
  lightestGray: "#f3f3f3",
  softGray: "#EFF0F2",
  hondaLight: "#fafafa",
  grey: "#989898",
  dark: "#222",
  primary: "#CC0000",
  white: "#ffffff",
  success: "#28A745",
  error: "#DD4C39",
  errorDark: "#C94434",
  warning: "#F39C11",
  warningDark: "#DD8E0F",
  blueHeader: "#141B2E",
  blueHeader2: "#0F1422",
  contactNumber: "#B1B1B1",
  black: "#000000",
  promoSubHeader: "#999999",
  yellowGreen: "#ABCC22"
};

export const spacing = {
  paddingX: 12,
  paddingY: 7,
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#CC0000",
    },
    secondary: {
      main: "#9a9a9a",
    },
    text: {
      primary: "#222222",
      secondary: "#9a9a9a",
    },
  },
  typography: {
    fontFamily: ["sans-serif", "Arial", "Helvetica", "Verdana"].join(","),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ":root": {
          "scroll-padding-top": "128px",
        },
      },
    },
  },
});

export default function AppThemeProvider({ children }: { children: ReactNode }) {
  const {
    themeData: { colorValue },
  } = useThemeLoader();

  const appTheme = createTheme({
    ...theme,
    palette: { ...theme.palette, primary: { main: colorValue } },
  });

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}