import { SxProps } from '@mui/system';
import { appColors } from '../../../theme/index';

export const BannerCarouselContainerStyle: SxProps = {
  maxWidth: { xs: '100%', sm: '100%' },
  height: 'inherit',
  display: 'flex',
  position: 'relative',
  '& .carousel-root': {
    width: '100%',
  },
  '& .carousel-root > .carousel': {
    overflow: 'unset',
    height: '100%',
  },
  '& .headers-carousel': {
    width: '100%',
  },
  '& .slider.animated': {
    transform: `translate3d(0%, 0px, 0px)`,
  },
  '& .carousel .slide' : {
    backgroundColor: appColors.blueHeader
  }
};

export const modelButtonStyles: SxProps = {
  border: '1.5px solid',
  borderColor: appColors.primary,
  background: 'white',
  textTransform: 'capitalize',
  borderRadius: '0px',
  width: '33vh',
  padding: '10px',
  ':hover': {
    backgroundColor: appColors.primary,
    color: appColors.white,
  },
};

export const imageContainer: SxProps = {
  display: 'flex',
  boxSizing: 'border-box',
  position: 'relative',
  aspectRatio: '16 / 9',
  justifyContent: 'center',
  height: { xs: "auto", sm: "83vh" },
  width: '100%',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  textAlign: 'start',
};

export const Heading: SxProps = {
  fontSize: { xs: '28px', md: '90px' },
  fontWeight: 'bold',
  color: appColors.white,
  lineHeight: '1',
};

export const Content: SxProps = {
  fontSize: { xs: '15px', md: '20px' },
  fontWeight: '400',
  padding: '10px 20px',
};

export const TextContainer: SxProps = {
  position: 'absolute',
  top: { xs: '10%', md: '25%' },
  color: appColors.white,
  textAlign: 'center',
  boxSizing: 'border-box',
  wordBreak: 'break-word',
  width: { xs: '100%', md: '60%' },
};

export const ButtonStyle: SxProps = {
  width: { xs: '10%', md: 'unset' },
  minHeight: { xs: '20px', md: 'unset' },
  marginTop: '20px',
};
