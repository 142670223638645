import { Stack } from "@mui/material";
import SectionTitle from "../../components/common/SectionTitle";
import PartsAndServiceList from "./PartsAndServiceList";
import WebOutlinedButton from "../../components/common/WebOutlinedButton";

const PartsAndService = () => {
  return (
    <>
      <SectionTitle title='Our Services' />
      <Stack mb={5}>
        <PartsAndServiceList />
      </Stack>
      <Stack alignItems="center">
        <WebOutlinedButton href="https://serviceonlinebooking.hondaphil.com/olb/index.html">
          Book now for appointment
        </WebOutlinedButton>
      </Stack>
    </>
  );
};

export default PartsAndService;