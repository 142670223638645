import { Route, Switch } from 'react-router-dom';

import { Fade } from '@mui/material';
import CompareModelsLayout from './fragments/CompareModelsLayout';
import SelectionModal from './fragments/SelectionModal';
import SectionContainer from '../../../components/layout/SectionContainer';
import { Box } from '@mui/system';
import Meta from '../../../components/common/Meta';

const CompareModelsPage = () => {
  return (
    <>
      <Meta title='Compare Models' />
      <Fade in timeout={700}>
        <Box>
          <SectionContainer>
            <Switch>
              <Route path='/models/compare-models/select-model-and-variant'>
                <SelectionModal />
              </Route>
            </Switch>
            <CompareModelsLayout />
          </SectionContainer>
        </Box>
      </Fade>
    </>
  );
};

export default CompareModelsPage;