import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectModelData, selectModelVariants } from "../../../../store/models/selectors";
import { setModelVariants } from "../../../../store/models/actions";
import { IModels } from "../../../../store/models/types";
import { 
  Stack, 
  Typography,
  Slide, 
  Dialog, 
  DialogContent, 
  IconButton 
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { CompareButton } from "./CompareModelComponents";
import { appColors } from "../../../../theme";
import CompareModel from "./CompareModel";
import CompareItem from "./CompareItem";

const SelectionModal = () => {
  const data = useSelector(selectModelData)
  const models = useSelector(selectModelVariants)
  const [selectedModel, setSelectedModel] = useState<IModels | undefined>();
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const history = useHistory();

  const handleClose = () => history.push("/models/compare-models");

  const handleClickModel = (model: IModels) => {
    setSelectedModel(model)
    dispatch(setModelVariants({ ...models, selectedModelIndex: models.selectedModelIndex - 1 }))
    setChecked((prev) => !prev);
  }

  const handleBack = () => {
    dispatch(setModelVariants({ ...models, selectedModelIndex: 0 }))
    setChecked((prev) => !prev);
  }

  const Header = (
    <Stack 
      alignItems={!checked ? 'center' : 'start'} 
      mt={2} 
      mb={ !checked ? 4 : 0 }
      borderBottom={!checked ? `1px solid ${appColors.lightGray}` : 'none'}
      paddingBottom={4}
    >
      { !checked 
        ? <Typography fontSize={25} fontWeight={600} lineHeight={1.1}>
            Select Model and Variant
          </Typography> 
        : <CompareButton 
            variant="outlined" 
            startIcon={<ChevronLeft fontSize='small' />}
            sx={{ padding: 0, fontSize: 16, '&:hover': { background: 'none' } }}
            onClick={handleBack}
          >
            Back to Car Models
          </CompareButton>
      }
    </Stack>
  )

  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth='sm'>
      <DialogContent>
        <Stack component='form' pb={4} sx={{ px: { xs: 0, md: 2 } }} ref={containerRef}>
          <IconButton
            onClick={handleClose}
            disableRipple
            aria-label='close'
            size='small'
            sx={{ position: "absolute", top: 12, right: 22 }}
          >
            <CloseIcon fontSize='small' sx={{ color: "#222" }} />
            </IconButton>
            { !checked 
              ? <React.Fragment>
                  {Header}
                  <Stack gap={2}>
                    { data.map(model => <CompareItem key={model.id} model={model} onChoose={handleClickModel} /> )}
                  </Stack>
                </React.Fragment> 
              : <Slide direction="left" in={checked} container={containerRef.current}>
                  <Stack component='section'>
                    {Header}
                    <CompareModel model={selectedModel}/>
                  </Stack>
                </Slide> }
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default SelectionModal;