import { Button, ButtonProps, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import latestPromoPageBackground from "../../../assets/images/disinfect-service-bg.png";

/** Latest Promo Container */
export const LatestPromoContainer = styled(Box)({
  background: `url(${latestPromoPageBackground}), linear-gradient(rgba(39,41,40,0.8), rgba(15,15,15,0.95))`,
  backgroundBlendMode: "darken",
  backgroundSize: "cover",
  backgroundPosition: "center",
  color: "#fff",
  padding: "48px 32px",
});

/** Responsive Paragraph includes breakpoints to control alignment */
export const ResponsiveTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
}));

export const HondaButtonStyle = styled(Button)(({ theme }) => ({
  color: "white",
  borderColor: "white",
  borderRadius: 8,
  paddingLeft: 25,
  paddingRight: 25,
  paddingTop: 7,
  paddingBottom: 7,
  textTransform: "capitalize",
  fontSize: 16,
  width: "fit-content",
  "&:hover": {
    borderColor: "rgb(188, 53, 50)",
    backgroundColor: "rgb(188, 53, 50)",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

interface HondaButtonProps {
  children?: React.ReactNode;
  display?: { xs?: "none" | "flex"; md?: "none" | "flex" };
  marginy?: number | string;
}

export const HondaButton = (props: HondaButtonProps & ButtonProps) => {
  const { display, children, marginy } = props;
  return (
    <HondaButtonStyle
      {...props}
      variant='outlined'
      endIcon={<ArrowForwardIcon />}
      sx={{
        display: display,
        marginTop: marginy,
        marginBottom: marginy,
      }}
    >
      {children}
    </HondaButtonStyle>
  );
};