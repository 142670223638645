import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchModels } from "../../store/models/actions";
import { fetchCarPricelist } from "../../store/pricelist/actions";
import { Switch, Route } from "react-router-dom";
import ModelList from "./ModelList";
import ModelDetails from "./ModelDetails";
import CompareModelsPage from "./compare-models";

const ModelsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchModels());
    dispatch(fetchCarPricelist());
  }, [dispatch]);

  return (
    <Switch>
      <Route path='/models/compare-models'>
        <CompareModelsPage />
      </Route>
      <Route path='/models/:id'>
        <ModelDetails />
      </Route>
      <Route path='/models'>
        <ModelList />
      </Route>
    </Switch>
  );
};

export default ModelsPage;