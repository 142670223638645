import { createStore, combineReducers, applyMiddleware, AnyAction } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
// @ts-ignore
import expireReducer from "redux-persist-expire";
import storage from "redux-persist/lib/storage";
import thunk, { ThunkAction } from "redux-thunk";
import systemReducers from "./system/reducers";
import authReducers from "./auth/reducers";
import careersReducer from "./careers/reducer";
import careersBannerReducer from "./careers-banner/reducer";
import { modelReducer, variantReducer } from "./models/reducers";
import partsAndServicesReducer from "./parts-and-service/reducers";
import partsAndServicesBannerReducer from "./parts-and-service-banner/reducer";
import inquiryReducer from "./inquiry/reducer";
import eliteReducer from "./elite/reducers";
import newsAndEventsReducer from "./news-and-events/reducer";
import promoReducer from "./promos/reducer";
import testimonialReducer from "./testimonial/reducer";
import virtualShowroomReducer from "./virtual-showroom/reducer";
import headerReducer from "./banner/reducer";
import partsAndAccessoriesReducer from "./parts-and-accessories/reducer";
import partsAndAccessoriesBannerReducer from "./parts-and-accessories-banner/reducer";
import pricelistReducer from "./pricelist/reducer";
import aboutUsReducer from "./about-us/reducer";
import quickLinksReducer from "./quick-links/reducer";
import generalJobReducer from "./general-job-application/reducer";
import navConfigReducer from "./nav-config/reducer";
import promoBannerReducer from "./promos-banner/reducer";

const rootPersistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["system", "article"],
  transform: [
    expireReducer("session", {
      expireSeconds: 3599,
      expiredState: {},
      autoExpire: true,
    }),
  ],
};

const loginPersistConfig = {
  key: "login",
  storage: storage,
};

const rootReducer = combineReducers({
  system: persistReducer(rootPersistConfig, systemReducers),
  auth: persistReducer(loginPersistConfig, authReducers),
  careers: careersReducer,
  careersBanner: careersBannerReducer,
  models: modelReducer,
  variants: variantReducer,
  partsAndServices: partsAndServicesReducer,
  partsAndServicesBanner: partsAndServicesBannerReducer,
  inquiry: inquiryReducer,
  elite: eliteReducer,
  newsAndEvents: newsAndEventsReducer,
  promo: promoReducer,
  promoBanner: promoBannerReducer,
  testimonial: testimonialReducer,
  virtualShowroom: virtualShowroomReducer,
  header: headerReducer,
  pricelist: pricelistReducer,
  aboutUs: aboutUsReducer,
  partsAndAccessories: partsAndAccessoriesReducer,
  partsAndAccessoriesBannerReducer: partsAndAccessoriesBannerReducer,
  quickLinks: quickLinksReducer,
  generalJob: generalJobReducer,
  navConfig: navConfigReducer
});

const middleware = [thunk];
const middlewareEnchancer = applyMiddleware(...middleware);
export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
export const store =
  process.env.NODE_ENV === "development"
    ? createStore(persistedReducer, composeWithDevTools(middlewareEnchancer))
    : createStore(persistedReducer, middlewareEnchancer);
export type AppState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<void | any, AppState, null, AnyAction>;
export type AppDispatch = typeof store.dispatch;

console.log(`NODE_ENV: ${process.env.NODE_ENV}`);
console.log(`REACT_APP_ENV: ${process.env.REACT_APP_ENV}`);

export const persistor = persistStore(store);