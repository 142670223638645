import { useHistory } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";

import SuccessMark from "./SuccessMark";

interface SuccessModalProps {
  message: string;
  open: boolean;
  moduleName?: string;
  callback?: () => void;
}

const SuccessModal = ({ message, open, moduleName, callback }: SuccessModalProps) => {
  const history = useHistory();

  const handleClose = () => {
    if (callback) callback();
  };

  const backToHome = () => {
    if (callback) callback();
    history.push("/home");
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
      <DialogContent>
        <IconButton
          onClick={handleClose}
          disableRipple
          aria-label='close'
          size='small'
          sx={{ position: "absolute", top: 12, right: 12 }}
        >
          <CloseIcon fontSize='small' sx={{ color: "#222" }} />
        </IconButton>
        <Stack my={6} gap={4} alignItems='center'>
          <SuccessMark />
          { moduleName && moduleName === "Parts and Accessories" ?
              <Stack>
                <Typography 
                  fontSize={26} 
                  fontWeight={600} 
                  textAlign='center' 
                  color={(theme) => theme.palette.primary.main}
                >
                  Good Honda Day to you!
                </Typography>
                <Typography fontSize={15} fontWeight={600} textAlign='center'>
                  Thank you for your inquiry.
                </Typography>
                <Typography fontSize={15} fontWeight={600} textAlign='center'>
                  Our team will contact you as soon as possible.
                </Typography>
              </Stack>
              :
              <Typography fontSize={24} fontWeight={600} textAlign='center'>
                {message}
              </Typography>
          }
          
          <Button
            variant='outlined'
            onClick={backToHome}
            sx={{
              padding: { xs: "12px 32px", md: "12px 32px" },
              fontSize: 15,
              borderRadius: 0,
              textTransform: "capitalize",
              px: 4,
              "&:hover": {
                bgcolor: (theme) => theme.palette.primary.main,
                color: "white",
              },
            }}
          >
            Back to Home
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessModal;