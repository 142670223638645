export const navItems = [
  {
    label: "Home",
    path: "/home",
  },
  {
    label: "Model",
    path: "/models",
  },
  {
    label: "Parts & Service",
    path: "/parts-and-accessories",
  },
  {
    label: "Promos",
    path: "/promos",
  },
  {
    label: "Careers",
    path: "/careers",
  },
  {
    label: "News & Events",
    path: "/news-and-events",
  },
  {
    label: "HCBT Elite Card",
    path: "/hcbt-elite",
  },
  {
    label: "About Us",
    path: "/about-us",
  },
];

export const navItemsv3 = [
  {
    label: "Home",
    path: "/home",
  },
  {
    label: "Models",
    path: "/models",
  },
  {
    label: "Parts & Accessories",
    path: "/parts-and-accessories",
  },
  {
    label: "Services",
    path: "/services",
  },
  {
    label: "Promos",
    path: "/promos",
  },
  {
    label: "Careers",
    path: "/careers",
  },
  {
    label: "News & Events",
    path: "/news-and-events",
  },
  {
    label: "HCBT Elite Card",
    path: "/hcbt-elite",
  },
  {
    label: "About Us",
    path: "/about-us",
  },
];