import { AppError, IFeatureState, Status } from "../system/types";

export interface IPAS {
  id:string;
  name:string;
  content:string;
  media:string;
};

export type PASListState = IFeatureState<IPAS>;

export const SET_PAS_STATUS = "pas/status/set";
export const SET_PAS_ERROR = "pas/error/set";
export const LOAD_PAS_DATA = "pas/data/load";

interface SetPASListStatusAction {
  type: typeof SET_PAS_STATUS;
  payload: { status: Status };
}

interface SetPASListErrorAction {
  type: typeof SET_PAS_ERROR;
  payload: { error: AppError };
}

interface SetPASListDataAction {
  type: typeof LOAD_PAS_DATA;
  payload: { data: PASListState["data"] };
}

export type PASListAction = 
  | SetPASListStatusAction
  | SetPASListErrorAction
  | SetPASListDataAction;