import { useState } from "react";
import { Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setInquiryStatus } from "../../store/inquiry/actions";

import { styled } from "@mui/material/styles";
import { Stack, Typography } from "@mui/material";
import ErrorModal from "../../components/common/ErrorModal";
import SuccessModal from "../../components/common/SuccessModal";
import WebInput from "../../components/common/WebInput";
import WebPrimaryButton from "../../components/common/WebPrimaryButton";
import AppUploadButton from "./AppUploadButton"
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import useInquiryForm from "../../hooks/useInquiryForm";

const UploadButton = styled("span")(({ theme }) => ({
  backgroundColor: "transparent", 
  border: "1px solid", 
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.main,
  padding: "5px",
  borderRadius: 5,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  transition: "transform 0.3s ease-in",
  marginLeft: "2px"
}));

interface CareerProps {
  careerName: string
}

const InquiryForm = ({ careerName }: CareerProps) => {
  const { control, register, errors, submitHandler, submitStatus, serverError, setValue, getValues } = useInquiryForm("Careers", careerName);
  const dispatch = useDispatch();
  const [attachmentName, setAttachmentName] = useState<string | null>("");
  const handleClose = () => dispatch(setInquiryStatus("idle"));

  return (
    <>
      <SuccessModal
        open={submitStatus === "success"}
        message='We have successfully received your application'
        callback={handleClose}
      />
      <ErrorModal open={submitStatus === "error"} message={serverError} callback={handleClose} />
      <Stack component='form' mt={1.4} gap={3} mb={8} onSubmit={submitHandler}>
        <Typography component='h3' fontWeight='600' fontSize={{ xs: 18, md: 24 }}>
          Inquire now
        </Typography>
        <Stack gap={2}>
          <Stack sx={{ flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
            <WebInput
              fullWidth
              placeholder='First Name'
              {...register("firstName")}
              error={!!errors?.firstName}
              helperText={errors?.firstName?.message}
            />
            <WebInput
              fullWidth
              placeholder='Last Name'
              {...register("lastName")}
              error={!!errors?.lastName}
              helperText={errors?.lastName?.message}
            />
          </Stack>
          <WebInput
            type='email'
            placeholder='Email'
            {...register("email")}
            error={!!errors?.email}
            helperText={errors?.email?.message}
          />
          <WebInput
            placeholder='Phone number'
            {...register("phoneNumber")}
            error={!!errors?.phoneNumber}
            helperText={errors?.phoneNumber?.message}
          />
          <WebInput
            placeholder='Upload Resume'
            value={attachmentName && getValues("mediaFile") ? attachmentName : ""}
            InputProps={{
              endAdornment:
              <>
                <Controller
                  name='mediaFile'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { name, ref } }) => (
                    <>
                      <AppUploadButton
                        onChange={(e) => {
                          if (e.currentTarget.files?.item(0) === null) return;

                          setValue("mediaFile", e.currentTarget.files?.item(0) || null);
                          setAttachmentName(e.currentTarget.files?.item(0)?.name || null)
                          e.target.value = "";
                        }}
                        ref={ref}
                        name={name}
                      />
                      { attachmentName && getValues("mediaFile") &&
                        <UploadButton 
                          onClick={() => {
                            setValue("mediaFile", null);
                            setAttachmentName("")
                          }}
                        >
                          <RemoveCircleOutlineIcon sx={{ color: theme => theme.palette.primary.main, fontSize: 13 }} />
                        </UploadButton>
                      }
                    </>
                  )}
                />
              </>
            }}
          />
          <WebInput
            multiline
            rows={4}
            placeholder='Comment or Message'
            {...register("comment")}
            error={!!errors?.comment}
            helperText={errors?.comment?.message}
          />
          <WebPrimaryButton
            type='submit'
            loading={submitStatus === "submitting"}
            sx={{ alignSelf: "start" }}
          >
            Submit
          </WebPrimaryButton>
        </Stack>
      </Stack>
    </>
  );
};

export default InquiryForm;