import SectionTitle from "../../../components/common/SectionTitle";
import SectionContainer from "../../../components/layout/SectionContainer";
import ModelContentCarousel from "../model-ui/ModelContentCarousel";
import useCurrentModel from "../utils/useCurrentModel";

const ModelAccessorySection = () => {
  const model = useCurrentModel();
  if (!model) return <></>;

  const { accessory } = model;

  return (
    <SectionContainer className='model-section' id='model-accessories'>
      <SectionTitle title='Accessories' />
      <ModelContentCarousel modelContentData={accessory} />
    </SectionContainer>
  );
};

export default ModelAccessorySection;