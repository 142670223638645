import { styled } from "@mui/system";

export const WebFilterTabItem = styled("button")(({ theme }) => ({
  fontSize: 15,
  position: "relative",
  cursor: "pointer",
  border: "none",
  backgroundColor: "white",
  color: "#aaa",

  fontFamily: "inherit",

  "&::after": {
    position: "absolute",
    bottom: -5,
    left: "50%",
    content: '""',
    width: 30,
    borderRadius: 2,
    height: 3,
    transform: "translateX(-50%)",
  },

  "&.active": {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },

  "&.active::after": {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const WebFilterTabs = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: 24,
  margin: "36px 0",
});