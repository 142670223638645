import type { AppThunk } from "../index";
import type { AppError, Status } from "../system/types";
import { apiClient, apiEndpoints } from "../utils/api";
import errorHandler from "../utils/errorHandler";
import {
  NewsAndEventsAction,
  INewsAndEvent,
  LOAD_ALL_NEWS_AND_EVENTS_DATA,
  LOAD_NEWS_AND_EVENTS_DATA,
  SET_NEWS_AND_EVENTS_STATUS,
  SET_NEWS_AND_EVENTS_FILTER,
  SET_NEWS_AND_EVENTS_ERROR,
  LOAD_MORE_NEWS_AND_EVENTS_DATA,
  NewsAndEventsState,
  SET_NEWS_AND_EVENTS_SORTBY,
} from "./types";

// Sync Actions
const setNewsAndEventStatus = (status: Status): NewsAndEventsAction => ({
  type: SET_NEWS_AND_EVENTS_STATUS,
  payload: { status },
});

const setNewsAndEventError = (error: AppError): NewsAndEventsAction => ({
  type: SET_NEWS_AND_EVENTS_ERROR,
  payload: { error },
});

const loadAllNewsAndEventData = (allData: INewsAndEvent[]): NewsAndEventsAction => ({
  type: LOAD_ALL_NEWS_AND_EVENTS_DATA,
  payload: { allData },
});

const loadNewsAndEventData = (data: INewsAndEvent[]): NewsAndEventsAction => ({
  type: LOAD_NEWS_AND_EVENTS_DATA,
  payload: { data },
});

const loadMoreNewsAndEventData = (data: INewsAndEvent[]): NewsAndEventsAction => ({
  type: LOAD_MORE_NEWS_AND_EVENTS_DATA,
  payload: { data },
});

export const setNewsAndEventFilter = (
  filter: INewsAndEvent["type"] | null
): NewsAndEventsAction => ({
  type: SET_NEWS_AND_EVENTS_FILTER,
  payload: { filter },
});

export const setNewsAndEventSortBy = (
  sortBy: NewsAndEventsState["sortBy"]
): NewsAndEventsAction => ({
  type: SET_NEWS_AND_EVENTS_SORTBY,
  payload: { sortBy },
});

// Async Actions
/**
 *
 * @description Fetches the NewsAndEvent list from the database
 */

export interface IFetchOptions {
  page: number;
  loadingState: "fetching" | "loading";
}

export const fetchAllNewsAndEvents = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setNewsAndEventStatus("fetching"));

    const response = await apiClient.request<{ newsAndEvents: INewsAndEvent[] }>({
      url: `${apiEndpoints.newsAndEvents}?active=true`,
    });

    dispatch(loadAllNewsAndEventData(response.data.newsAndEvents));
  } catch (err: any) {
    dispatch(setNewsAndEventStatus("error"));
    dispatch(setNewsAndEventError(err));
    errorHandler(err, dispatch);
  }
};

export const fetchNewsAndEvents =
  (type: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setNewsAndEventStatus("fetching"));

      const limit = 9;
      const offset = 0;

      const query =
        type === "All"
          ? `${apiEndpoints.newsAndEvents}?offset=${offset}&limit=${limit}&active=true`
          : `${apiEndpoints.newsAndEvents}?offset=${offset}&limit=${limit}&active=true&type=${type}`;

      const response = await apiClient.request<{ newsAndEvents: INewsAndEvent[] }>({
        url: query,
      });

      dispatch(loadNewsAndEventData(response.data.newsAndEvents));
    } catch (err: any) {
      dispatch(setNewsAndEventStatus("error"));
      dispatch(setNewsAndEventError(err));
      errorHandler(err, dispatch);
    }
  };

export const loadMoreNewsAndEvents =
  (page: number): AppThunk =>
  async (dispatch) => {
    try {
      if (page === 1) return;

      dispatch(setNewsAndEventStatus("loading"));

      const limit = 9;
      const offset = limit * (page - 1);

      const response = await apiClient.request<{ newsAndEvents: INewsAndEvent[] }>({
        url: `${apiEndpoints.newsAndEvents}?offset=${offset}&limit=${limit}&active=true`,
      });

      dispatch(loadMoreNewsAndEventData(response.data.newsAndEvents));
    } catch (err: any) {
      dispatch(setNewsAndEventStatus("error"));
      dispatch(setNewsAndEventError(err));
      errorHandler(err, dispatch);
    }
  };

export const newsAndEventsFetcher = async (page: number, type: "All" | "News" | "Event") => {
  const limit = 9 * page;
  const offset = 0;

  const query =
    type === "All" || !type
      ? `${apiEndpoints.newsAndEvents}?offset=${offset}&limit=${limit}&active=true`
      : `${apiEndpoints.newsAndEvents}?offset=${offset}&limit=${limit}&active=true&type=${type}`;

  const response = await apiClient.request<{ newsAndEvents: INewsAndEvent[], count: number }>({
    url: query,
  });

  const newsAndEvents = response.data.newsAndEvents;
  const totalNewsAndEvents = response.data.count;

  return { newsAndEvents, totalNewsAndEvents };
};

export const newsAndEventsFetchById = async (id: string) => {
  const response = await apiClient.request<{ newsAndEvent: INewsAndEvent }>({
    url: `${apiEndpoints.newsAndEvents}/${id}`,
  });

  return response.data.newsAndEvent;
};