import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { styled } from "@mui/material/styles";
import { appColors } from "../../../../theme";

export const SpecificationAccordion = styled(Accordion)({
  backgroundColor: appColors.lightestGray,
  border: "none",
  boxShadow: "none",
  "&:before": { display: "none" },
  marginBottom: "10px",
});

export const SpecificationAccordionDetails = styled(AccordionDetails)({
  backgroundColor: "white",
  paddingTop: 15,
  paddingBottom: 15,
  borderLeft: `1px solid ${appColors.lightestGray}`,
  borderRight: `1px solid ${appColors.lightestGray}`,
  borderBottom: `1px solid ${appColors.lightestGray}`,
});

export const SpecificationAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  paddingBottom: 5,
  paddingTop: 5,

  "& svg": {
    color: theme.palette.primary.main,
  },
}));