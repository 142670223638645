import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { submitInquiry, submitCareerInquiry } from "../store/inquiry/actions";
import { selectInquiryState } from "../store/inquiry/selectors";

const phoneRegExp = /^(09|\+639)\d{9}$/;

const inquirySchema = yup
  .object({
    firstName: yup.string().required("First name is required").trim(),
    lastName: yup.string().required("Last name is required").trim(),
    email: yup.string().required("Email is required").email("Provide a valid email").trim(),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Invalid phone number")
      .required("Phone number is required"),
    comment: yup.string().required("Kindly provide a comment/message"),
  })
  .required();

interface InquiryFields {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  comment: string;
  mediaFile?: File | null;
}

const defaultInquiryValues: InquiryFields = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  comment: "",
  mediaFile: null
};

export default function useInquiryForm(moduleName: string, careerName?: string) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors, isValid, isSubmitting, isSubmitSuccessful },
  } = useForm<InquiryFields>({
    defaultValues: defaultInquiryValues,
    resolver: yupResolver(inquirySchema),
    mode: "onChange",
  });

  const { status, error } = useSelector(selectInquiryState);
  const dispatch = useDispatch();

  const onSubmit = (formData: InquiryFields) => {
    if (status === "submitting") return;

    if (moduleName === "Careers" && careerName) dispatch(submitCareerInquiry(formData, moduleName, careerName, reset));
    else dispatch(submitInquiry(formData, moduleName, reset));

  };

  const submitHandler = handleSubmit(onSubmit);

  return {
    register,
    setValue,
    getValues,
    control,
    errors,
    submitHandler,
    isValid,
    isSubmitting,
    submitStatus: status,
    isSubmitSuccessful,
    serverError: error ? error.message : "Something went wrong :(",
  };
}