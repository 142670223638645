import { SxProps } from "@mui/system";
import background from "../../../assets/images/models-background.png";
import { appColors } from "../../../theme/index";

export const ModelCarouselContainerStyle: SxProps = {
  maxWidth: { xs: "100%", sm: "100%" },
  height: "inherit",
  display: "flex",
  position: "relative",
  // padding: { xs: "50px 20px", sm: "70px 40px" },
  backgroundPosition: "center",
  backgroundImage: `url(${background})`,
  "& .carousel-root": {
    width: "100%",
  },
  "& .carousel-root > .carousel": {
    overflow: "unset",
    height: "100%",
    // padding: "0px 45px",
  },
  "& .models-carousel": {
    width: "100%",
  },
  "& .slider.animated": {
    transform: `translate3d(0%, 0px, 0px)`,
  },
};

export const modelButtonStyles: SxProps = {
  border: "1.5px solid",
  borderColor: appColors.primary,
  background: "white",
  textTransform: "capitalize",
  borderRadius: "0px",
  width: "33vh",
  padding: "10px",
  ":hover": {
    backgroundColor: appColors.primary,
    color: appColors.white,
  },
};

export const modelContainer: SxProps = {
  width: "auto",
  margin: "20px",
  transition: "transform 200ms ease-in-out",
  "&:hover": {
    transform: { xs: "scale(1)", md: "scale(1.1)" },
    cursor: "pointer",
  },
  "&:hover > .view-model": {
    animation: "fadeIn 15s",
    color: { xs: "transparent", md: "primary.main" },
  },
  ".view-model": {
    color: "transparent",
    marginBottom: "15px",
  },
};

export const imageContainer: SxProps = {
  display: "flex",
  boxSizing: "border-box",
  position: "relative",
  "& img": {
    width: "100%",
    aspectRatio: "16 / 9",
    objectFit: "cover",
  },
};