import { Stack } from '@mui/material';
import Meta from '../../components/common/Meta';
import SectionTitle from '../../components/common/SectionTitle';
import NewsAndEventsFilter from './NewsAndEventsFilter';

import NewsAndEventsList from './NewsAndEventsList';
import NewsAndEventSort from './NewsAndEventSort';

const NewsAndEventsListView = () => {
  return (
    <>
      <Meta title='News and Events' />
      <SectionTitle title='News and Events' />
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <NewsAndEventsFilter />
        <NewsAndEventSort />
      </Stack>
      <NewsAndEventsList />
    </>
  );
};

export default NewsAndEventsListView;