import { apiClient, apiEndpoints } from "../utils/api";
import type { AppThunk } from "../index";
import type { Status } from "../system/types"; 
import errorHandler from "../utils/errorHandler";
import {
  SET_PARTS_AND_ACCESSORIES_STATUS,
  SET_PARTS_AND_ACCESSORIES_FILTER,
  LOAD_PARTS_AND_ACCESSORIES_DATA,
  IPartsAndAccessories,
  PartsAndAccessoriesListAction
} from "./types";

const setPartsAndAccessoriesStatus = (status: Status): PartsAndAccessoriesListAction => ({
  type: SET_PARTS_AND_ACCESSORIES_STATUS,
  payload: { status }
});

const loadPartsAndAccessoriesData = (data: IPartsAndAccessories[]): PartsAndAccessoriesListAction => ({
  type: LOAD_PARTS_AND_ACCESSORIES_DATA,
  payload: { data }
});

export const setPartsAndAccessoriesFilter = (
  filter: IPartsAndAccessories["type"]
): PartsAndAccessoriesListAction => ({
  type: SET_PARTS_AND_ACCESSORIES_FILTER,
  payload: { filter },
});

export const fetchPartsAndAccessoriesList = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setPartsAndAccessoriesStatus("loading"));
    
    const response = await apiClient.request<{ partsAndAccessoriesData: IPartsAndAccessories[] }>({
      url: `${apiEndpoints.partsAndAccessories}?active=true`
    });

    dispatch(loadPartsAndAccessoriesData(response.data.partsAndAccessoriesData))
  } catch (err: any) {
    dispatch(setPartsAndAccessoriesStatus("error"));
    errorHandler(err, dispatch);
  }
}


export const partsAndAccessoriesFetcher = async (type: "All" | "Part" | "Accessory" | "Others") => {
  const query = type === "All" 
                ? `${apiEndpoints.partsAndAccessories}?active=true` 
                : `${apiEndpoints.partsAndAccessories}?active=true&type=${type}`;

  const response = await apiClient.request<{ partsAndAccessoriesData: IPartsAndAccessories[] }>({
    url: query,
  });

  const partsAndAccessories = response.data.partsAndAccessoriesData;

  return { partsAndAccessories };
}