import { Stack } from "@mui/material";
import SectionContainer from "../../components/layout/SectionContainer";
import { TextHeader } from "./ui";

interface HCBTProps {
  header: string;
  content: string;
}

const HCBT = (props: HCBTProps) => {
  const { header, content } = props;

  return (
    <SectionContainer>
      <Stack gap={2}>
        <TextHeader>{header}</TextHeader>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Stack>
    </SectionContainer>
  );
}

export default HCBT;
