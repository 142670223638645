import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { selectPartsAndServicesListState } from "../../store/parts-and-service/selectors";
import PASItem from "./PASItem";
import { useEffect } from "react";
import { fetchPASList } from "../../store/parts-and-service/actions";
import FetchingLoadingIndicator from "../../components/common/FetchingLoadingIndicator";

const PartsAndServiceList = () => {
  const dispatch = useDispatch();
  const { status, data } = useSelector(selectPartsAndServicesListState);

  useEffect(() => {
    dispatch(fetchPASList());
  }, [dispatch]);

  if (status === "loading")
    return (
      <Stack minHeight="45vh">
        <FetchingLoadingIndicator />
      </Stack>
    );

  return (
    <Stack gap={1.7} mt={2}>
      {data.map((item) => (
        <PASItem key={item.id} ipas={item} />
      ))}
    </Stack>
  );
};

export default PartsAndServiceList;