export type InquiryStatus = "idle" | "submitting" | "success" | "error";

export interface InquiryState {
  status: InquiryStatus;
  error: Error | undefined;
}

export interface InquiryFields {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  comment: string;
  mediaFile?: File | null;
}

export interface IPartsAndAccessoryInquiry extends InquiryFields {
  chassisNumber: string;
  model: string;
  attachmentURL?: string;
  attachmentName? : string;
}

export interface ICareerInquiry extends InquiryFields {
  attachmentURL?: string;
  attachmentName? : string;
  careerName: string;
}

export const SET_INQUIRY_STATUS = "inquiry/status/set";
export const SET_INQUIRY_ERROR = "inquiry/error/set";

interface SetInquiryStatusAction {
  type: typeof SET_INQUIRY_STATUS;
  payload: { status: InquiryStatus };
}

interface SetInquiryErrorAction {
  type: typeof SET_INQUIRY_ERROR;
  payload: { error: Error | undefined };
}

export type InquiryAction = SetInquiryStatusAction | SetInquiryErrorAction;