import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { selectNewsAndEventsLatestData } from "../../../store/news-and-events/selectors";
import { Stack } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import { NextButton, PrevButton } from "./ui";
import { NewsAndEventsCarouselContainerStyle, NewsEventBox } from "./NewsAndEventsStyle";
import EventIcon from "@mui/icons-material/Event";
import NewsAndEventItem from "./NewsAndEventsItem";
import { fetchNewsAndEvents } from "../../../store/news-and-events/actions";
import SectionContainer from "../../../components/layout/SectionContainer";
import SectionTitle from "../../../components/common/SectionTitle";
import WebOutlinedButton from "../../../components/common/WebOutlinedButton";
import { CarouselIndicator } from "../../model/model-ui/model-content-carousel/ui";
import useIsTabletOrMobile from "../../../hooks/useIsTabletOrMobile";

const NewsAndEventsCarousel = () => {
  const dispatch = useDispatch();

  const newsAndEvents = useSelector(selectNewsAndEventsLatestData);

  useEffect(() => {
    dispatch(fetchNewsAndEvents("All"));
  }, [dispatch]);

  const history = useHistory();
  const isTabletOrMobile = useIsTabletOrMobile();

  const viewAllNewsAndEvents = () => history.push("/news-and-events");

  return (
    <Stack sx={NewsAndEventsCarouselContainerStyle}>
      <SectionContainer>
        <NewsEventBox
          className="newsAndEvents-carousel"
          gap={5}
          count={newsAndEvents.length}
        >
          <SectionTitle title="News & Events" />
          <Carousel
            showIndicators={
              newsAndEvents
                ? (newsAndEvents.length > 3 && !isTabletOrMobile) ||
                  (newsAndEvents.length >= 2 && isTabletOrMobile)
                  ? true
                  : false
                : false
            }
            showStatus={false}
            showThumbs={false}
            infiniteLoop={newsAndEvents ? (newsAndEvents.length > 3 ? true : false) : false}
            centerMode={true}
            centerSlidePercentage={isTabletOrMobile ? 100 : newsAndEvents.length === 2 ? 100 / 2 : 100 / 3}
            renderArrowNext={(onClick, hasNext, label) =>
              newsAndEvents ? (
                newsAndEvents.length > 3 ? (
                  <NextButton
                    onClick={onClick}
                    hasNext={hasNext}
                    label={label}
                  />
                ) : null
              ) : null
            }
            renderArrowPrev={(onClick, hasPrev, label) =>
              newsAndEvents ? (
                newsAndEvents.length > 3 ? (
                  <PrevButton
                    onClick={onClick}
                    hasPrev={hasPrev}
                    label={label}
                  />
                ) : null
              ) : null
            }
            renderIndicator={(clickHandler, isSelected, index, label) => (
              <CarouselIndicator
                clickHandler={clickHandler}
                isSelected={isSelected}
                index={index}
                label={label}
              />
            )}
          >
            {newsAndEvents.map((item) => (
              <NewsAndEventItem item={item} key={item.id} />
            ))}
          </Carousel>
          <Stack
            gap={2}
            justifyContent="center"
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              marginTop: newsAndEvents ? (newsAndEvents.length > 1 ? 5 : 0) : 0,
            }}
          >
            <WebOutlinedButton
              startIcon={<EventIcon />}
              onClick={viewAllNewsAndEvents}
            >
              View All News and Events
            </WebOutlinedButton>
          </Stack>
        </NewsEventBox>
      </SectionContainer>
    </Stack>
  );
};

export default NewsAndEventsCarousel;