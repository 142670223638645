import { SxProps } from "@mui/system";

export const controlButtonsStyles: SxProps = {
  visibility: { xs: "hidden", md: "visible" },
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  bgcolor: "white",
  border: "1px solid #333",
  borderRadius: 0,
  "&:hover": {
    bgcolor: "white",
  },
  "&:disabled": {
    bgcolor: "white",
    borderColor: "rgba(0,0,0,0.26)",
  },
};

export const containerStyles: SxProps = {
  px: { xs: 2, md: 8 },
  mb: 8,
  position: "relative",
  "& .carousel-slider": { position: "initial" },
  "& .control-dots": {
    transform: { xs: "translateX(-32px)", md: "translateX(-128px)" },
    bottom: { xs: -80, md: -80 },
  },
};