import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import NewQuickLinkItem from "./NewQuickLinkItem";
import QuickLinkForm from "./QuickLinkForm";
import { fetchQuickLinks } from "../../../store/quick-links/actions";
import { selectQuickLinkslData } from '../../../store/quick-links/selectors';
import { quickLinkItems } from "./utils";

const NewQuickLinks = () => {
  const [currentQuickLink, setCurrentQuickLink] = useState<string | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchQuickLinks());
  }, [dispatch]);

  const quickLinksMedia = useSelector(selectQuickLinkslData);

  quickLinkItems[0].image = quickLinksMedia[0]?.promosMedia;
  quickLinkItems[1].image = quickLinksMedia[0]?.vehicleSalesMedia;
  quickLinkItems[2].image = quickLinksMedia[0]?.serviceAppointmentMedia;
  quickLinkItems[3].image = quickLinksMedia[0]?.partsMedia;

  return (
    <>
      <Stack alignItems='center'>
        <Box
          display='grid'
          width='100%'
          mx={{ xs: 0, md: 8 }}
          mt={4}
          gap={3}
          gridTemplateColumns={{ xs: "repeat(1, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
        >
          <NewQuickLinkItem 
            quickLink={quickLinkItems[0]}
            onClick={() => {
              history.push('promos');
            }}
          />
          <NewQuickLinkItem 
            quickLink={quickLinkItems[1]}
            onClick={setCurrentQuickLink}
          />
          <a href="https://serviceonlinebooking.hondaphil.com/olb/index.html" target='_blank' rel='noopener'>
            <NewQuickLinkItem 
              quickLink={quickLinkItems[2]}
              onClick={() => {}}
            />
          </a>
          <NewQuickLinkItem 
            quickLink={quickLinkItems[3]}
            onClick={setCurrentQuickLink}
          />
        </Box>
      </Stack>
      {currentQuickLink && (
        <QuickLinkForm title={currentQuickLink} open onClose={() => setCurrentQuickLink(null)} />
      )}
    </>
  );
};

export default NewQuickLinks;