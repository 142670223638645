import { KeyboardArrowDown } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import { useState, MouseEvent } from "react";
import WebSortButton from "../../components/common/WebSortButton";
import useNewsAndEventsState from "./utils/useNewsAndEventsState";

const NewsAndEventSort = () => {
  const { sortBy, onSortByChange, status } = useNewsAndEventsState();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (status === "fetching") return null;

  return (
    <div>
      <WebSortButton
        id='sort-button'
        aria-controls={open ? "sort-button-menu" : undefined}
        aria-haspopup='true'
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Sort <KeyboardArrowDown />
      </WebSortButton>
      <Menu
        id='sort-button-menu'
        anchorEl={anchorEl}
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "sort-button",
        }}
      >
        <MenuItem
          sx={{ fontSize: 15, color: sortBy === "dateCreated:desc" ? "primary.main" : "inherit" }}
          onClick={() => onSortByChange("dateCreated:desc")}
        >
          Newest to Oldest
        </MenuItem>
        <MenuItem
          sx={{ fontSize: 15, color: sortBy === "dateCreated:asc" ? "primary.main" : "inherit" }}
          onClick={() => onSortByChange("dateCreated:asc")}
        >
          Oldest to Newest
        </MenuItem>
      </Menu>
    </div>
  );
};

export default NewsAndEventSort;