import * as React from "react";
import { useSelector } from "react-redux";
import { ModelVariantSpecs } from "../../../../store/models/types";
import { selectModelVariants } from "../../../../store/models/selectors";

import { Grid, Stack, Typography, Accordion } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import {
  CompareAccordion,
  CompareAccordionDetails,
  CompareAccordionSummary,
  HondaButton,
  AccordionContainerStyle,
} from "./CompareModelComponents";
import { appColors } from "../../../../theme";
import specs from "./specs.json";

export const ModelAccordion = ({ hide }: { hide?: boolean }) => {
  const { selectedModels } = useSelector(selectModelVariants);
  const [expandAll] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [categories, setExpandedCategories] = React.useState<Array<string>>([]);
  const iconStyle = { color: appColors.primary, mx: { xs: 0, md: 3 } };

  const isSpecMatch = (
    spec: ModelVariantSpecs,
    category: string,
    label: string
  ) => {
    if (spec.category === category) {
      let value = "-";
      for (const s of spec.items)
        if (s.label === label && s.value !== "") value = s.value;
      return value;
    }
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (categories.includes(panel))
        setExpandedCategories(
          categories.filter((category) => category !== panel)
        );
      else setExpandedCategories((categories) => [...categories, panel]);
    };

  const isExpandedIcon = (category: string) => {
    if (expandAll) {
      setExpandedCategories(specs.map((c) => c.category) as Array<string>);
      return <Remove sx={iconStyle} />;
    }

    if (categories.includes(category)) return <Remove sx={iconStyle} />;
    else return <Add sx={iconStyle} />;
  };

  const handleExpandAll = () => {
    setExpandedCategories(specs.map((c) => c.category) as Array<string>);
    setOpen(false);
  };

  const handleCollapseAll = () => {
    setExpandedCategories([]);
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Stack
        gap={2}
        flexDirection="row"
        justifyContent="end"
        alignItems="center"
      >
        {
          // ugly way to avoid unlimited re-render
          open ? (
            <HondaButton
              className="expand-all-btn"
              marginy={3}
              onClick={handleExpandAll}
            >
              EXPAND ALL
            </HondaButton>
          ) : (
            <HondaButton
              className="expand-all-btn"
              marginy={3}
              onClick={handleCollapseAll}
            >
              COLLAPSE ALL
            </HondaButton>
          )
        }
      </Stack>
      {specs.map((spec, index) => (
        <Stack
          sx={AccordionContainerStyle}
          key={spec.category + index.toString()}
        >
          <Accordion
            expanded={categories.includes(spec.category)}
            onChange={handleChange(spec.category)}
            sx={CompareAccordion}
            TransitionProps={{
              unmountOnExit: true,
            }}
            disableGutters
          >
            <CompareAccordionSummary expandIcon={isExpandedIcon(spec.category)}>
              <Typography
                fontWeight={500}
                ml={{ xs: 0, md: 3 }}
                sx={{
                  fontSize: { xs: "13px", sm: "17px" },
                }}
              >
                {spec.category}
              </Typography>
            </CompareAccordionSummary>
            <CompareAccordionDetails>
              <Grid container gap={4} flexDirection="column">
                {spec.items.map((item, index) => (
                  <Grid
                    key={index}
                    container
                    padding={0}
                    borderBottom={`1px solid ${appColors.lightestGray}`}
                  >
                    <Grid item xs={6} md={3} paddingBottom={3}>
                      <Typography
                        mx={{ xs: 0, md: 3 }}
                        fontWeight={500}
                        sx={{
                          fontSize: { xs: "12px", sm: "16px" },
                        }}
                      >
                        {item.label}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      md={3}
                      paddingBottom={3}
                      textAlign="center"
                    >
                      <Typography
                        mx={{ xs: 0, md: 3 }}
                        fontWeight={500}
                        sx={{
                          fontSize: { xs: "12px", sm: "16px" },
                        }}
                      >
                        {selectedModels[0].variant[0].specs.map((spek) =>
                          isSpecMatch(spek, spec.category, item.label)
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      md={3}
                      paddingBottom={3}
                      textAlign="center"
                    >
                      <Typography
                        mx={{ xs: 0, md: 3 }}
                        fontWeight={500}
                        sx={{
                          fontSize: { xs: "12px", sm: "16px" },
                        }}
                      >
                        {selectedModels[1].variant[0].specs.map((spek) =>
                          isSpecMatch(spek, spec.category, item.label)
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      md={3}
                      paddingBottom={3}
                      textAlign="center"
                    >
                      <Typography
                        mx={{ xs: 0, md: 3 }}
                        fontWeight={500}
                        sx={{
                          fontSize: { xs: "12px", sm: "16px" },
                        }}
                      >
                        {selectedModels[2].variant[0].specs.map((spek) =>
                          isSpecMatch(spek, spec.category, item.label)
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </CompareAccordionDetails>
          </Accordion>
        </Stack>
      ))}
    </React.Fragment>
  );
};

export default ModelAccordion;