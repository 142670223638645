import { forwardRef } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";

const WebInput = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  return (
    <TextField
      aria-label={props.placeholder}
      {...props}
      ref={ref}
      size='medium'
      color='secondary'
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: 0,
          "& > input ": {
            padding: "12px",
          },
        },
      }}
      InputProps={{ sx: { fontSize: "14px" }, ...props.InputProps }}
    />
  );
});

export default WebInput;