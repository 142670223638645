import { Box, Stack, Typography } from "@mui/material";
import { ModelContent } from "../../../../store/models/types";
import { ModelContentImage } from "./ui";

const ModelContentCarouselItem = ({ modelContentItem }: { modelContentItem: ModelContent }) => {
  const { header, content, imagePreviewUrl } = modelContentItem;

  return (
    <Box
      display='grid'
      gap={{ xs: 4, md: 12 }}
      gridTemplateColumns={{ xs: "1fr", md: "1fr 1.5fr" }}
    >
      <Stack
        sx={{
          textAlign: { xs: "center", md: "left" },
        }}
        gap={{ xs: 2, md: 4 }}
        order={{ xs: 2, md: 1 }}
      >
        <Typography
          component='h3'
          fontFamily='Roboto'
          fontSize={{ xs: 24, md: 36 }}
          fontWeight={500}
          lineHeight={1.3}
        >
          {header}
        </Typography>
        <Typography
          fontFamily='Roboto'
          fontSize={14}
          fontWeight={400}
          color='#8E8E8E'
          lineHeight={1.8}
          sx={{
            paddingX: { xs: "0px", sm: "10%", md: "0px" },
          }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Stack>
      <Box
        order={{ xs: 1, md: 2 }}
        sx={{
          paddingX: { xs: "0px", sm: "10%", md: "0px" },
        }}
      >
        <ModelContentImage
          src={imagePreviewUrl}
          alt={header}
          loading='lazy'
          width='100%'
          height='100%'
        />
      </Box>
    </Box>
  );
};

export default ModelContentCarouselItem;