import axios from "axios";

// CONSTANTS
// const apiversion = "v1";

export const apiEndpoints = {
  careers: "careers",
  models: "models",
  partsServices: "parts-and-services",
  sendInquiry: "sendInquiry",
  elite: "elite",
  newsAndEvents: "news-and-events",
  promos: "promos",
  testimonial: "testimonies",
  headers: "headers",
  virtualShowroom: "virtual-showroom",
  theme: "themes",
  pricelist: "car-pricelist",
  aboutUs: "about-us",
  partsAndAccessories: "parts-and-accessories",
  quickLinks: "quick-links",
  generalJob: "general-job",
  navConfig: "nav-config"
};

// ENV VARIABLES/TOKENS
export const API_URL = process.env.REACT_APP_API_URL;

// this is temporary. this must be a token from an authenticated user
export const AUTH_TOKEN = process.env.REACT_APP_ACCESS_TOKEN;
export const LOGIN_TOKEN = process.env.REACT_APP_API_LOGIN_TOKEN;

export const loginAxiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${LOGIN_TOKEN}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${AUTH_TOKEN}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});