import { Stack, Typography, Box } from "@mui/material";
import { IPAS } from "../../store/parts-and-service/types";
import { appColors } from "../../theme";
import { Image } from "./PAS_Style";

interface PASItemProps {
  ipas: IPAS;
}

const PASItem = ({ ipas }: PASItemProps) => {
  return (
    <Stack
      py={2}
      px={3}
      bgcolor={appColors.hondaLight}
      justifyContent='flex-start'
      columnGap='25px'
      sx={{
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Box>
        <Image
          src={ipas.media}
          alt={ipas.name}
          sx={{
            width: { xs: "100%", sm: "100%", md: "200px" },
            height: { xs: "200px", sm: "200px", md: "175px" },
            border: 1, 
            borderColor: "primary.main",
          }}
        />
      </Box>
      <Stack
        sx={{
          width: "100%",
          margin: "0 0 0 20",
          overflowWrap: "break-word",
          alignItems: { xs: "center", sm: "center", md: "start" },
        }}
      >
        <Typography fontSize={18} fontWeight='bold'>
          {ipas.name}
        </Typography>
        <Typography fontSize={15} fontWeight={300} maxWidth='100%' textAlign= {{ xs: "center", sm: "center", md: "start" }}>
          {ipas.content}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default PASItem;