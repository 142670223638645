import { SxProps } from "@mui/system";

export const controlButtonsStyle: SxProps = {
  visibility: { xs: "hidden", md: "visible" },
  position: "absolute",
  top: "48%",
  bgcolor: "white",
  border: "1px solid #333",
  borderRadius: 0,
  "&:hover": {
    bgcolor: "white",
  },
  "&:disabled": {
    bgcolor: "white",
    borderColor: "rgba(0,0,0,0.26)",
  },
};

export const containerStyles: SxProps = {
  px: { xs: 2, md: 8 },
  mb: 10,
  mt: 2,
  position: "relative",
  "& .carousel-slider": { position: "initial" },
  "& .control-dots": {
    transform: { xs: "translateX(-16px)", md: "translateX(-64px)" },
    bottom: { xs: -60, md: -80 },
  },
};